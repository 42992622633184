import React, { useState } from 'react';
import QRScanner from '../../assets/QRScanner'; // Make sure to replace with actual QRScanner component
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { fetchToAddNewOvenInBatch } from '../../reduxStore/OvenIn/OvenInSlice';
import { useNavigate } from 'react-router-dom'; // Correct import for navigation
import DataOfOvenBatchWise from './DataOfOvenBatchWise';

const MultipleFgIdsInput = ({ scannerIdName, labelName, onSubmitGetAllInputIds, checkIsIdExist, oven, addMouldInWhichBatchId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Correct hook for navigation
    const [findIfExistsInTagList, setFindIfExistsInTagList] = useState(false);
    const [newIdInfo, setNewIdInfo] = useState({
        mouldIds: "",
        addInWhichBatch: addMouldInWhichBatchId,
        inWhichOven: oven.serialNo
    });

    const handleInputChange = (e) => {
        e.preventDefault();
        setNewIdInfo((prevState) => ({
            ...prevState,
            mouldIds: e.target.value
        }));
    };

    const resultOnScan = (result) => {
        const updatedIdInfo = {
            ...newIdInfo,
            mouldIds: result
        };

        setNewIdInfo(updatedIdInfo);
        addNewMouldInOven(updatedIdInfo); // Pass the updated state directly
    };

    const addNewMouldInOven = async (updatedIdInfo) => {
        try {
            const response = await dispatch(fetchToAddNewOvenInBatch(updatedIdInfo)).unwrap();
            console.log("response", response);
            if (addMouldInWhichBatchId === "generateNewBatchID" && response.success) {
                navigate('/'); // Navigate to the home page or desired route
                // navigate('/placeMouldInOven', { state: { oven: oven , batchId:response.inBatchId} })
            }
        } catch (error) {
            console.error("Error adding mould:", error);
        }
    };

    return (
        <>
            <div className="max-w-sm mx-auto max-sm:mx-4 shadow-xl rounded p-4 my-12">
                <div className='font-bold text-lg mx-auto w-full text-xs'>"{oven.serialNo}" & "{addMouldInWhichBatchId}"</div>
                <label htmlFor={scannerIdName} className="block mt-2 text-sm font-medium text-teal-900">
                    <QRScanner idName={scannerIdName} buttonName="Scan IDs" resultOnScanSuccess={resultOnScan} />
                </label>
                <div className="grid grid-cols-3 gap-4">
                    <div className="max-w-sm col-span-3">
                        <input
                            id={scannerIdName}
                            name="mouldIds"
                            type="text"
                            value={newIdInfo.mouldIds}
                            onChange={handleInputChange}
                            className={`${findIfExistsInTagList ? 'bg-red-900 border border-red-300' : 'bg-teal-900 border border-teal-300'} text-white text-sm rounded-lg block w-full p-2.5`}
                            placeholder="Enter Mould ID (M0012)"
                        />
                    </div>
                    <button
                        type="button"
                        onClick={() => addNewMouldInOven(newIdInfo)}
                        className="col-span-3 text-teal-700 hover:text-white border border-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2 mb-2"
                    >
                        Submit
                    </button>
                </div>
            </div>

            <DataOfOvenBatchWise batchID={addMouldInWhichBatchId}/>
        </>
    );
};

export default MultipleFgIdsInput;
