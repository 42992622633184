import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchToLogout } from '../reduxStore/authentication/userAuthSlice';
import { Navigate } from "react-router-dom";

const Logout = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (window.confirm("Do you really want to log out?")) {           
            dispatch(fetchToLogout());
        }
        
    }, [dispatch]);
  return (
    <>
      <Navigate to="/" />
    </>
  )
}

export default Logout
