import React, { useEffect, useState } from 'react';
import FormatTime from '../../assets/FormatTime';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMouldIDsBatchWise } from '../../reduxStore/OvenIn/OvenInSlice';

const DataOfOvenBatchWise = ({ batchID }) => {

  const dispatch = useDispatch();
  const batchData = useSelector((state) => state.ovenBatchWiseDataReducer);
  const [ovenBatchData, setOvenBatchData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchMouldIDsBatchWise(batchID));
    };
    fetchData();
  }, [batchID, dispatch]);

  useEffect(() => {
    if (batchData.data && batchData.data.mouldIDs && batchData.data.mouldIDs.ovenInSemiFGIds) {
      setOvenBatchData(batchData.data.mouldIDs.ovenInSemiFGIds);
    }
  }, [batchData]);

  return (
    <>




      <div className="relative overflow-x-auto shadow-md rounded-lg mx-2" >
        <table className="w-full text-[10px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <caption className="p-5 text-[10px] font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Our products
          </caption>
          <thead className="text-[10px] text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Mould ID</th>
              <th scope="col" className="px-6 py-3">In Time</th>

              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {ovenBatchData.map((currItem, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{currItem.mould}</th>
                <th className="px-6 py-4">{FormatTime(currItem.inTime)}</th>
                <th className="px-6 py-4 text-right">
                  <button href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                    Remove
                  </button>
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </>
  );
};

export default DataOfOvenBatchWise;
