import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchToRejectNewLcBottle } from '../../reduxStore/LcRejection/LcRejectionSlice';
import QRScanner from '../../assets/QRScanner';

const RejectLc = () => {
  const dispatch = useDispatch();
  const [lcRejectionData, setLcRejectionData] = useState({
    lcId: "",
    reasonOfRejection: "",
    remarks: "",
  });

  const handleInputDataOfLcRejection = (key, value) => {
    setLcRejectionData(prevState => ({ ...prevState, [key]: value }));
  };

  const submitFilledLcRejectionData = async (e) => {
    e.preventDefault();
    
    const submissionData = {
      lcId: lcRejectionData.lcId,
      reasonOfRejection: lcRejectionData.reasonOfRejection === "Other"
        ? `Other: ${lcRejectionData.remarks}`
        : lcRejectionData.reasonOfRejection,
    };

    try {
      const response = await dispatch(fetchToRejectNewLcBottle(submissionData)).unwrap();
      if (response.success) {
        setLcRejectionData({
          lcId: "",
          reasonOfRejection: "",
          remarks: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const lcIDResult = (result) => {
    setLcRejectionData(prevState => ({ ...prevState, lcId: result }));
  };

  const clearAll = () => {
    setLcRejectionData({
      lcId: "",
      reasonOfRejection: "",
      remarks: "",
    });
  };

  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    if (window.confirm("Do you really want to On/Off?")) {
      setChecked(!checked);
    }
  };

  return (
    <form onSubmit={submitFilledLcRejectionData} className="max-w-sm bg-teal-50 p-4 sm:mx-auto mx-4 my-4 border border-teal-200 rounded-lg shadow-lg justify-center">

        <label className="inline-flex items-center me-5 cursor-pointer p-1">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={checked}
            onChange={handleToggle}
          />
          <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 dark:text-black-300">
            {checked ? "Off Scanner" : "On Scanner"}
          </span>
        </label>

        <label htmlFor="lcId" className={`block mt-2 text-sm font-medium text-teal-900 `}>
            <span className="text-black-600 dark:text-black-500">LC ID:</span>
          </label>

      <label htmlFor="lcId" className={`block  text-sm font-medium text-teal-900 ${checked ? "" : "hidden"}`}>
        {/* Scan LC ID */}
        <QRScanner hidden={checked} idName={"lcIdScanner"} buttonName={"Scan LC ID"} resultOnScanSuccess={lcIDResult} />
      </label>
      <input
        type="text"
        id="lcId"
        value={lcRejectionData.lcId}
        name="lcId"
        onChange={(e) => handleInputDataOfLcRejection('lcId', e.target.value)}
        className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
        placeholder="Input LC ID"
        required
      />

      <label htmlFor="reasonOfRejection" className="block mt-2 text-sm font-medium text-teal-900">
        Reason of Rejection
      </label>
      <select
        id="reasonOfRejection"
        value={lcRejectionData.reasonOfRejection}
        name="reasonOfRejection"
        onChange={(e) => handleInputDataOfLcRejection('reasonOfRejection', e.target.value)}
        className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
        required
      >
        <option value="">Select Reason...</option>
        <option value="Turbidity">Turbidity</option>
        <option value="Green Mold">Green Mold</option>
        <option value="No growth">No growth</option>
        <option value="Orange Mold">Orange Mold</option>
        <option value="Black Mold">Black Mold</option>
        <option value="Media leak from bottle">Media leak from bottle</option>
        <option value="Other">Other</option>
      </select>

      {lcRejectionData.reasonOfRejection === "Other" && (
        <>
          <label htmlFor="remarks" className="block mt-2 text-sm font-medium text-teal-900">
            Remarks for other
          </label>
          <input
            type="text"
            id="remarks"
            value={lcRejectionData.remarks}
            name="remarks"
            onChange={(e) => handleInputDataOfLcRejection('remarks', e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
            placeholder="Pink Mold"
            required
          />
        </>
      )}

      <div className='py-4'>
        <button
          type="submit"
          className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          Submit
        </button>

        <button
          type="button"
          onClick={clearAll}
          className="ml-2  text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          Clear All
        </button>
      </div>
    </form>
  );
};

export default RejectLc;
