import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHumidifiers } from '../../reduxStore/GrowthChamber/HumidifierSlice';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';


const Humidifier = () => {
    const [num, setNum] = useState(0);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const humidifiers = useSelector((state) => state.humidifierReducer);
    const [allDataFromCollection, getAllDataFromCollection] = useState();
    useEffect(() => {
        dispatch(fetchHumidifiers())
        // console.log("Humidifers: ",humidifiers)
        // return () => { };
    }, [dispatch]);

    useEffect(() => {
        if (humidifiers.data && humidifiers.data.humidifiers) {
            getAllDataFromCollection(humidifiers.data.humidifiers)
        }
    }, [humidifiers]);


    // console.log("location.state", location);
    const handleClean = async (h) => {
        // e.preventDefault();
        console.log(h)
        try {
            // const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST_URL;
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
            // const url = `${BACK_HOST}humidifier?id=${h.serialNo}`;
            const url = `${BACK_HOST}api/v1/humidifier?id=${h.serialNo}`;
            let data = {
                'status': 'empty'
            };
            console.log("Data: ", data)
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            // console.log("response: ", response)
            if (!response.ok) {
                const errorData = await response.json();
                // toast.error(errorData.message);
                return errorData
            }

            const res = await response.json();
            console.log("submit res:", res);
            return res;

        } catch (error) {
            console.error("Error in updating humidifier ", error);
            throw error;
        }
    }
    const [searchQuery, setSearchQuery] = useState('');

    // console.log("humidifiers.data.humidifiers",allDataFromCollection);
    const searchHumidifier = allDataFromCollection?.filter((currHum) => currHum.serialNo.includes(searchQuery))
    // humidifiers.data && humidifiers.data.humidifiers
    // console.log("searchHumidifier",searchHumidifier);

    return (
        <>
          

                <div className='flex items-center  justify-between  w-full flex-column flex-wrap md:flex-row '> 
                    <h2 className='text-3xl font-bold m-4 text-center'>Humidifiers</h2>
                    {/* <h2 className='text-3xl font-bold m-4 text-center'>Search</h2> */}

                    <div className="relative mx-6 my-2 p-1 flex justify-end ">
                        <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg
                                className="w-4 h-4 text-black-500 dark:text-black-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                            </svg>
                        </div>
                        <input
                            type="text"
                            id="table-search-users"
                            className="block p-2 ps-10 text-sm text-black-900 border border-black-900 rounded-lg w-80 bg-black-50 focus:ring-black-500 focus:border-black  dark:bg-black-700 dark:border-black  dark:placeholder-black-400 dark:text-black dark:focus:ring-black-500 dark:focus:border-black "
                            placeholder="Search Humidifier"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>

           
            <hr />
            <div className='flex flex-wrap justify-center p-5'>
                {/* {console.log("data:", humidifiers.data)} */}
                {searchHumidifier?.map((h) => (
                    <div key={h.serialNo} className={`m-2 w-60  ${h.status === 'empty' && 'bg-green-200'} ${h.status === 'notFullyFilled' && 'bg-orange-200'} ${h.status === 'filledCompletely' && 'bg-blue-200'} ${h.status === 'readingCollected' && 'bg-pink-200'} ${h.status === 'clean' && 'bg-red-200'}  ${h.status === 'maintenance' && 'bg-red-200'} border border l-200 rounded-lg shadow-lg flex `}>
                        <div className="p-5 flex flex-col h-full w-full justify-between">
                            <div className="flex flex-col justify-between h-1/3">
                                <h5 className="mr-2 text-2xl font-bold tracking-tight text-teal-900">Humidifier: {h.serialNo} </h5>
                                <h5 className="text-md tracking-tight text-teal-900"> <span className='font-bold'>Type : </span>{h.type}</h5>
                                <h5 className="text-md tracking-tight text-teal-900"> <span className='font-bold'>Cycle Count : </span>{h.status == 'clean' ? 6 : h.cycleCount % 6}</h5>
                            </div>

                            <div className="flex flex-row mt-2 gap-2 justify-center ">
                                {h.status === 'empty' &&
                                    <button type="button" onClick={() => {
                                        navigate('/placeMould', { state: { humidifier: h } })
                                    }} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 " >Place Mould</button>}

                                {/* {h.status === 'notFullyFilled' &&
                                    <button type="button" onClick={() => {
                                        navigate('/placeMoreMoulds', { state: { humidifier: h } })
                                    }} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 " >
                                        Place More Mould</button>} */}

                                {
                                    <button type="button" onClick={() => {
                                        navigate('/placeMould', { state: { humidifier: h } })
                                    }} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 " >
                                        Place More Mould</button>}


                                {h.status === 'filledCompletely' &&
                                    <button type="button" onClick={() => {
                                        navigate('/reading', { state: { humidifier: h } })
                                    }} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" >Reading</button>}

                                {h.status === 'readingCollected' &&
                                    <button type="button" className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" onClick={() => {
                                        navigate('/remove', { state: { humidifier: h } })
                                    }} >Remove</button>}

                                {h.status === 'clean' &&
                                    <button type="button" onClick={(e) => {
                                        handleClean(h)
                                    }} className="text-red-600 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 " >Clean</button>}

                                {

                                    h.status === 'maintenance' &&
                                    <button type="button" className="text-red-600 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 " >Maintenance</button>

                                }

                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </>
    );
};

export default Humidifier;