import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import OvenInNewSemiFGBatch from './OvenInNewSemiFGBatch'
import Login from '../../assets/Login'
import Logout from '../../assets/Logout'
import Ovens from './Ovens'
import BatchesInOven from './BatchesInOven'


const NavigateInMouldIncubation = () => {
    return (
        <>
            <BrowserRouter>
                <Navbar moduleName={"Oven In"} home={"/"} viewData={"/viewDataOfOvenIn"} />
                <Routes>
                    <Route path="/" element={<Ovens />} />
                    <Route path="/placeMouldInBatch" element={<BatchesInOven />} />
                    {/* <Route path="/placeMouldInOven" element={<OvenInNewSemiFGBatch />} /> */}
                    <Route path="/placeMouldInOven" element={<OvenInNewSemiFGBatch />} />
                    <Route path="/myProfile" element={<ProfileCard />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default NavigateInMouldIncubation
