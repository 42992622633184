import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchToPrepareNewLc } from '../../reduxStore/LcPreparation/LcPreparationSlice';
import QRScanner from '../../assets/QRScanner';

const CreateNewLc = () => {
  const dispatch = useDispatch();
  const [lcPreparationData, setLcPreparationData] = useState({
    lcId: "",
    lcQty: "",
    scId: "",
    scQty: ""
  });

  const handleInputDataOfLcPreparation = (key, value) => {
    setLcPreparationData({ ...lcPreparationData, [key]: value });
  };

  const submitFilledLcPreparationData = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(fetchToPrepareNewLc(lcPreparationData)).unwrap();
      console.log("response", response);
      if (response.success) {

        setLcPreparationData({
          lcId: "",
          lcQty: "",
          scId: lcPreparationData.scId,
          scQty: lcPreparationData.scQty
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearAll = async () => {
    setLcPreparationData({
      lcId: "",
      lcQty: "",
      scId: "",
      scQty: ""
    });
  };

  const lcIDResult = (result) => {
    setLcPreparationData({ ...lcPreparationData, lcId: result });
  };
  const lcQtyResult = (result) => {
    setLcPreparationData({ ...lcPreparationData, lcQty: result });
  };

  const scIDResult = (result) => {
    setLcPreparationData({ ...lcPreparationData, scId: result });
  };
  const scQtyResult = (result) => {
    setLcPreparationData({ ...lcPreparationData, scQty: result });
  };

  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    if (window.confirm("Do you really want to On/Off?")) {
      setChecked(!checked);
    }
  };

  return (
    <>
      <form onSubmit={submitFilledLcPreparationData} className="max-w-sm  p-4 sm:mx-auto mx-4 my-4 border  rounded-lg shadow-lg justify-center">


        <label className="inline-flex items-center me-5 cursor-pointer p-1">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={checked}
            onChange={handleToggle}
          />
          <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 dark:text-black-300">
            {checked ? "Off Scanner" : "On Scanner"}
          </span>
        </label>

        <div className="">

          <label htmlFor="lcId" className={`block mt-2 text-sm font-medium text-teal-900 `}>
            <span className="text-black-600 dark:text-black-500">LC ID:</span>
          </label>
          <label htmlFor="lcId" className={`block  text-sm font-medium text-teal-900 ${checked ? "" : "hidden"}`}>
            <QRScanner hidden={checked} idName={"lcIdScanner"} buttonName={"Scan LC ID"} resultOnScanSuccess={lcIDResult} />
          </label>
          



          <input
            type="text" id="lcId" value={lcPreparationData.lcId} name="lcId"
            onChange={(e) => handleInputDataOfLcPreparation(e.target.name, e.target.value)}
            className="border border-teal-300 rounded-md px-4 py-2 w-full"
            placeholder="Input LC ID"
            required
          />
        </div>

        <div className="">
          <label htmlFor="lcId" className={`block mt-2 text-sm font-medium text-teal-900 `}>
            <span className="text-black-600 dark:text-black-500">LC Quantity:</span>
          </label>
          <label htmlFor="lcQty" className={`block  text-sm font-medium text-teal-900 ${checked ? "" : "hidden"}`}>
            {/* Fill LC Quantity (in ml.) */}
            <QRScanner hidden={checked} idName={"lcQtyScanner"} buttonName={"Scan LC Qty."} resultOnScanSuccess={lcQtyResult} />
          </label>
          <input
            type="number" id="lcQty" value={lcPreparationData.lcQty} name="lcQty"
            onChange={(e) => handleInputDataOfLcPreparation(e.target.name, e.target.value)}
            className="border border-teal-300 rounded-md px-4 py-2 w-full"
            placeholder="Fill LC Quantity"
            required
            min={0}
          />
        </div>


        <div className="">

          <label htmlFor="lcId" className={`block mt-2 text-sm font-medium text-teal-900 `}>
            <span className="text-black-600 dark:text-black-500">SC ID:</span>
          </label>

          <label htmlFor="scId" className={`block  text-sm font-medium text-teal-900 ${checked ? "" : "hidden"}`}>
            
            <QRScanner hidden={checked} idName={"scIdScanner"} buttonName={"Scan SC ID"} resultOnScanSuccess={scIDResult} />
          </label>
          <input
            type="text" id="scId" value={lcPreparationData.scId} name="scId"
            onChange={(e) => handleInputDataOfLcPreparation(e.target.name, e.target.value)}
            className="border border-teal-300 rounded-md px-4 py-2 w-full"
            placeholder="Scan SC ID"
            required
          />
        </div>

        <div className="">
        <label htmlFor="lcId" className={`block mt-2 text-sm font-medium text-teal-900 `}>
            <span className="text-black-600 dark:text-black-500">SC Quantity:</span>
          </label>

          <label htmlFor="scQty" className={`block text-sm font-medium text-teal-900 ${checked ? "" : "hidden"}`}>
            <QRScanner hidden={checked} idName={"scQtyScanner"} buttonName={"Scan SC Qty."} resultOnScanSuccess={scQtyResult} />
            {/* Input SC Quantity (in ml.) */}
          </label>
          <input
            type="number" id="scQty" value={lcPreparationData.scQty} name="scQty"
            onChange={(e) => handleInputDataOfLcPreparation(e.target.name, e.target.value)}
            className="border border-teal-300 rounded-md px-4 py-2 w-full"
            placeholder="Input SC Quantity"
            required
            min={0}
          />
        </div>



        <div className='py-2'>
          <button
            type="submit"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Submit
          </button>

          <button
            type="button"
            onClick={() => clearAll()}
            className="ml-2 text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Clear All
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateNewLc;
