import React from 'react'

const DateFilterButtons = ({chartName}) => {
  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between">
          <div className="font-bold text-lg text-black mb-4 sm:mb-0">{chartName}</div>

          <div className="flex flex-col sm:flex-row justify-center items-center p-2 border rounded-lg bg-gray-100">
            <div className="flex flex-wrap  justify-center  ">
              <button className="px-4 py-2 m-1 bg-green-500 text-white rounded-md">Today</button>
              <button className="px-4 py-2 m-1 bg-gray-200 text-gray-800 rounded-md">Yesterday</button>
              <button className="px-4 py-2 m-1 bg-gray-200 text-gray-800 rounded-md">Starting Date</button>
              <button className="px-4 py-2 m-1 bg-gray-200 text-gray-800 rounded-md">End Date</button>
            </div>
          </div>
          
        </div>
    </>
  )
}

export default DateFilterButtons
