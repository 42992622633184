import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const fetchToCheckIsIdExist = createAsyncThunk('ovenin/fetchToCheckIsIdExist', async (id, { rejectWithValue }) => {
    try {
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/checkingOfExistence`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(id),
            credentials: 'include'
        }); 

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            return rejectWithValue(errorData.message || 'Failed to add new batch of semi-finished goods.');
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    } catch (error) {
        console.error('Error while adding new ID to Ovenin: ', error);
        return rejectWithValue(error.message);
    }
});


export const fetchToAddNewOvenInBatch = createAsyncThunk('ovenin/fetchToAddNewOvenInBatch', async (newBatchData , {rejectWithValue}) => {
      try {
        console.log(newBatchData);
  
        // Ensure the BACK_HOST is correctly defined in your .env file
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/ovenInMouldsBatchesWise`;
  
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newBatchData),
          credentials: 'include', // Makes sure cookies are sent with the request
        });
  
        // If the response is not OK, handle error and provide user feedback
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.message);
          return errorData;
        }
  
        // Extract response data
        const data = await response.json();
        toast.success(data.message);
        return data;
  
      } catch (error) {
        console.error('Error while adding new batch to Ovenin: ', error);
        // Use rejectWithValue to return the error message in a more controlled manner
        return rejectWithValue(error.message || 'Something went wrong.');
      }
    }
  );


  //? fetch Data of oven batch wise. 
  export const fetchMouldIDsBatchWise = createAsyncThunk('ovenin/fetchMouldIDsBatchWise', async(batchID)=>{
    try{

        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/getMouldIDsBatchWise/${batchID}`;
        const response = await fetch(url,{
            method:"GET",
            credentials: 'include',
        })

        if (!response.ok) {
            // toast.error()
            throw new Error('Failed to fetch data of oven of batch wise.');
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    }catch(error){
        console.error("Something Went Wrong to fetching data of oven in batch wise",error);
        throw new Error(error);
        
    }
  })
  

//? Thunk to fetch all data of Ovenin
export const fetchAllOvenInData = createAsyncThunk('ovenin/fetchAllOvenInData', async (queryParams) => {
    try {
        const params = new URLSearchParams(queryParams);
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/getDataOfOvenIn?${params}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Failed to fetch all Ovenin data.');
        }

        const data = await response.json();
        // console.log("DataGGG",data);
        return data;

    } catch (error) {
        console.error('Error while fetching all Ovenin data: ', error);
        throw error;
    }
});

//? Thunk to delete Ovenin data by ID
export const fetchToDeleteOvenInData = createAsyncThunk('ovenin/fetchToDeleteOvenInData', async (id) => {
    try {
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/admin/delOvenInSemiGoods/${id}`;
        const response = await fetch(url, {
            method: 'DELETE',
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            throw new Error(errorData.message || 'Failed to delete Ovenin data.');
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    } catch (error) {
        console.error('Error while deleting Ovenin data: ', error);
        throw error;
    }
});

// Thunk to update Ovenin data by ID
export const fetchToUpdateOvenInData = createAsyncThunk('ovenin/fetchToUpdateOvenInData', async ({ id, updatedData }) => {
    try {
       
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/admin/updateOvenInSemiFGData/${id}`;
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            throw new Error(errorData.message || 'Failed to update Ovenin data.');
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    } catch (error) {
        console.error('Error while updating Ovenin data: ', error);
        throw error;
    }
});

const oveninSlice = createSlice({
    name: 'ovenin',
    initialState: {
        data: null,
        isLoading: false,
        isError: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        
            //? For adding new batch of semi-finished goods
            .addCase(fetchToCheckIsIdExist.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToCheckIsIdExist.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(fetchToCheckIsIdExist.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })


            //? For adding new batch of semi-finished goods
            .addCase(fetchToAddNewOvenInBatch.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToAddNewOvenInBatch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToAddNewOvenInBatch.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })


            //? fetch Data of oven batch wise.
            .addCase(fetchMouldIDsBatchWise.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchMouldIDsBatchWise.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchMouldIDsBatchWise.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })



            //? For fetching all Ovenin data
            .addCase(fetchAllOvenInData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAllOvenInData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchAllOvenInData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })



            //? For deleting Ovenin data
            .addCase(fetchToDeleteOvenInData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToDeleteOvenInData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToDeleteOvenInData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })



            //? For updating Ovenin data
            .addCase(fetchToUpdateOvenInData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToUpdateOvenInData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToUpdateOvenInData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default oveninSlice.reducer;