import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAllOvens } from '../../reduxStore/OvenIn/OvenSlice';

const Ovens = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allOvens = useSelector((state) => state.ovenReducer);
    const [allDataFromCollection, setGetAllDataFromCollection] = useState();
    useEffect(() => {
        dispatch(fetchAllOvens());
      }, [dispatch]);

      console.log("Hiiii",allDataFromCollection);
      


      useEffect(() => {
        if (allOvens && Array.isArray(allOvens?.data?.oven)) {
          setGetAllDataFromCollection(allOvens.data.oven);
        } else {
          setGetAllDataFromCollection([]); // Default to an empty array if data is not an array
        }
      }, [allOvens]);

    const [searchQuery, setSearchQuery] = useState('');
    const searchedOven = allDataFromCollection?.filter((currHum) => currHum.serialNo.includes(searchQuery))


    return (
        <>
                <div className='flex items-center  justify-between  w-full flex-column flex-wrap md:flex-row '> 
                    <h2 className='text-xl font-bold mx-4 mt-2 text-center'>Ovens</h2>

                    <div className="relative mx-6 mb-2 sm:mt-2 p-1 flex justify-end w-[250px]">
                        <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none ">
                            <svg
                                className="w-4 h-4 text-black-500 dark:text-black-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                            </svg>
                        </div>
                        <input
                            type="text"
                            id="table-search-users"
                            className=" block p-2 ps-10 text-sm text-black-900 border border-black-900 rounded-lg w-80 bg-black-50 focus:ring-black-500 focus:border-black  dark:bg-black-700 dark:border-black  dark:placeholder-black-400 dark:text-black dark:focus:ring-black-500 dark:focus:border-black "
                            placeholder="Search Oven"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>

           
            <hr />
            <div className='flex flex-wrap justify-center p-5'>
                {searchedOven?.map((oven) => (
                    <div key={oven.serialNo} className={`m-2 w-60  ${oven.status === 'empty' && 'bg-green-200'} ${oven.status === 'filled' && 'bg-orange-200'} border border l-200 rounded-lg shadow-lg flex `}>
                        <div className="p-5 flex flex-col h-full w-full justify-between">
                            <div className="flex flex-col justify-between h-1/3">
                                <h5 className="mr-2 text-2xl font-bold tracking-tight text-teal-900">OVEN: {oven.serialNo} </h5>
                            </div>

                            <div className="flex flex-row mt-2 gap-2 justify-center ">
                                {oven.status === 'empty' &&
                                    <button type="button" onClick={() => {
                                        navigate('/placeMouldInBatch', { state: { oven: oven } })
                                    }} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 " >Place Mould</button>}

                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </>
    );
};

export default Ovens;
